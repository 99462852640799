<template>
    <div>
      <innerheader />
        <login />
    </div>
</template>

<script>
import login from "@/components/loginpage.vue";
import innerheader from "@/components/innerheader/innerHeaderDIY.vue";

export default {
  name: 'loginpage',
  components: {
     innerheader,
     login
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/scss/components/loginpage.scss";
@import "~@/assets/scss/fonts.scss";
</style>