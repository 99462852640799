<template >
<div class="container-login">
  <div>
    <div class="row">
        <div class="col-sm-9 col-md-7 col-lg-5 mx-auto ">
        </div>
        </div>
    <div class="row">
      <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
        <div class="card border-0 shadow rounded-3 my-5">
          <div class="card-body p-4 p-sm-5">
            <form @submit.prevent="login()"> 
              <div class="form-floating mb-3">
                <label for="floatingInput">Email </label>
                <input type="email" class="form-control" @change="validateEmail()" v-model="email" id="floatingInput" placeholder="Please enter your email here">
                <label class="errormsg" >{{this.errormsg}}</label>
              </div>
              <div class="form-floating mb-3">
                <label for="floatingPassword">Password</label>
                <input v-if="show" type="text" class="form-control" v-model="password" autocomplete="off" id="floatingPassword" placeholder="Password">
                <input v-else type="password" class="form-control" v-model="password" autocomplete="off" id="floatingPassword" placeholder="Password">

            </div>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input"  @change="showPassword()" id="showPass">
                <label class="custom-control-label" for="showPass">Show Password</label>
                </div>
                <label class="errormsg">{{this.errormsglogin}}</label>
              <div class="d-grid">
                <button class="btn btn-primary btn-login text-uppercase fw-bold" type="submit">Sign
                  in</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    </div> 
  </div>
</template>
<script>
import jwt_decode from "jwt-decode";

export default {
  name: "loginpage",
  components: {

  },
  data(){
    return{
    email: "",
    password: "",
    show: false,
    errormsg:"",
    errormsglogin:"",
    token:"",
    decoded:"",
    current_user:"",

    };
  },

  methods: {
    showPassword() {
      this.show = !this.show;
    },
    validateEmail() {
        console.log("validate email called");
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,64})+$/.test(this.email)) {
        this.errormsg = "";
    } else {
        this.errormsg= "Please enter a valid email address";
       
    }
    },
    login(){
      if(!this.email || !this.password){
        this.errormsg = !this.email ? "Please enter a valid email address": "";
        this.errormsglogin = !this.password ? "Please enter password": "";      
      }
      else{
        let qData={
            "distributorID":"PINC",
            "email": this.email,
            "password": this.password,
            "authUsing": "basicAuth",
            "insuranceCompanyCode": "ICICI",
            "UITrackingRefNo": "ed1bbbd2-d3fc-4df9-befe-952d46996958",
            "OTPTransactionId":"",
            "OTP":""
        };

        var headers = {
          Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
          "Content-Type": "application/json",
        };
        this.backendApi("userLogin", qData, headers)
          .then((response) => {
            if(response == 'error') {
              self.apiError = true;
            }
             else if(response && response.data){
              
                
                if(response.data.errors.length==0){ 
                this.token = response.data.data.token.access_token;
                sessionStorage.setItem('access_token',JSON.stringify((this.token)));
                try{
                    this.decoded = jwt_decode(this.token)
                    // console.log("token",this.decoded)
                    this.current_user = this.decoded;
                    // console.log("transid",transId)

                    let transId = this.current_user.PAYLOAD.transaction_id
                    this.$router.push(new URL(location).origin+'/#/?id='+transId );

                    
                    
                }
                catch(err){
                    console.log('token is null: ',err);
                }
                }
                else{
                    this.errormsglogin= response.data.errors[0].errorMessage
                }
            }
          })
          .catch((error) => {
            console.log('error msg block email', error);
          });
      }

    },

  },
  mounted(){

  },
};
</script>
<style scoped lang="scss">
@import "~@/assets/scss/components/loginpage.scss";
@import "~@/assets/scss/fonts.scss";
</style>
