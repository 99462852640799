<template>
  <div class="loading_block_outer" v-if="loading">
    <Loading2 />
  </div>
<div v-else>
  <div class="inner-page-bg">
    <div class="checkout-container">
      <div>
        <router-link to="/proposal" class="back-to-link">
          <img src="~@/assets/images/back-arrow.png" alt="" />
          Back to Proposal
        </router-link>
      </div>
      <!-- <div class="text-light bg-danger mb-3" v-if="spDetailsError">SP details not available, please login first!</div> -->
      

      <div class="policyname" v-if="selectedPlan">
        <div class="policyname-text d-flex align-items-center">
          
          <router-link to="/proposal" class="back-arrow">
            <img src="~@/assets/images/left-arrow-grey.png" alt="">
          </router-link>
          <span class="mr-4">
           <img
              :src="
                getInsurerImgs(
                  selectedPlan['insuranceAndProducts']['insuranceCompanyCode']
                )
              "
              alt=""
            />
          </span>
          <h6>{{ selectedPlan['insuranceAndProducts']['productName'] }}</h6>

        </div>
        <div class="d-flex justify-content-end flex-column">
          <label v-if="this.applicationNo != '' && this.applicationNo != null " class="appno">Application Number : {{this.applicationNo}}</label>
          <label v-if="this.uiQuoteId != '' && this.uiQuoteId != null " class="propno">Reference Number : {{this.uiQuoteId}}</label>
          <div class="d-flex justify-content-end">
            <a :href="selectedPlan.other.biUrl" target="_blank">
              <button class="bi-btn next-btn"> Benefit Illustration </button>
            </a>
          </div>
      </div>
      </div>
      <div class="chckout-table rounded position-relative">
        <h5>Please review below details before proceeding ahead.</h5>
        <span class="subtext d-block text-center">These cannot be changed at a later stage</span>
        <div class="accordion" id="checkoutaccordion">
          <div class="card z-depth-0" v-for="(value, ind) in formInfo.fieldGroups" :key="ind">
            <div class="" v-if="ind != 'traceInfo' && ind != 'distributor'">
              <div class="card-header" :id="'headingOne_' + ind" v-if="!value.visibility?true:checkVisibility(value.visibility)">
                <button class="btn btn-link" type="button" data-toggle="collapse" :data-target="'#collapseOne_' + ind" aria-expanded="true" :aria-controls="'collapseOne_' + ind">
                  <!-- <h6>{{ convertSentenceCase(value["name"]) }}</h6> -->
                  <h6>{{ convertSentenceCase(value["label"]) }}</h6>
                </button>
                <div class="d-flex cursor-pointer" @click="editDetails(ind)">
                  <img src="~@/assets/images/edit-profile.png">
                  Edit
                </div>
              </div> 
              
              <div :id="'collapseOne_' + ind" :class="'collapse ' + getShow(ind)" :aria-labelledby="'headingOne_' + ind" data-parent="#checkoutaccordion" v-if="!value.visibility?true:checkVisibility(value.visibility)">
                <div class="card-body">
                  <div class="without-fieldset">
                    <!-- If Fields -->
                    <div class="pro-details">
                      <template class="" v-for="(value2, ind2) in value.fields" :key="ind2"> 
                        <div class="mb-4" v-if="value2.type == 'static'">
                          <div>
                            <h6>{{ value2["label"] }}</h6>
                          </div>
                        </div>
                        <div class="mb-4" v-if="value2.input != ''">
                          <div v-if="value2.type == 'single-select'">
                            <h6>{{ value2["label"] }}</h6>
                            <b>{{ getSelectValue(value2.value, value2["input"]) }}</b>
                          </div>
                          <div v-else-if="value2.type == 'currency'">
                            <h6>{{ value2["label"] }}</h6>
                            <b>{{ this.currency(value2["input"]) }}</b>
                          </div>
                          <div v-else-if="value2.type == 'multi-select'">
                            <h6>{{ value2["label"] }}</h6>
                            <!-- <b>{{ getMultiSelectValue(value2.value, value2["input"]) }}</b> -->
                            <b>{{ value2["input"].toString() }}</b>
                          </div>
                          <div v-else-if="value2.type == 'boolean'">
                            <h6>{{ value2["label"] }}</h6>
                            <b>{{ (value2["input"] == '1')?'Yes':'No' }}</b>
                          </div>
                          <div v-else-if="value2.type == 'date'">
                            <h6>{{ value2["label"] }}</h6>
                            <b>{{ dateconvert(value2["input"]) }}</b>
                          </div>
                          <div v-else-if="value2.type == 'file'">
                            <h6>{{ value2["label"] }}</h6>
                            <button type="button" class="btn btn-primary eyeicon-btn" data-toggle="modal" data-target="#imageModal" v-if="formInfo.fieldGroups[ind].fields[ind2].input" @click="previewFiles(formInfo.fieldGroups[ind].fields[ind2].input)">
                              <img src="~@/assets/images/eye.png" alt="" />
                            </button>
                          </div>
                          <div v-else-if="value2.type == 'checkbox'">
                            <h6>
                              <img v-if="value2.input" src="~@/assets/images/check-mark.svg" class="check-markimg" alt=""/> 
                              {{ value2["label"] }}
                            </h6>
                            <!-- <b>{{ value2["input"] }}</b> -->
                          </div>
                          <div v-else>
                            <h6>{{ value2["label"] }}</h6>
                            <b>{{  value2["input"] }}</b>
                          </div>
                        </div>
                        <div v-if="value2.input == '0' && value2.type == 'number'"> <!-- to handle case where input is zero and type number -->
                            <h6>{{ value2["label"] }}</h6>
                              <b>{{ value2["input"] }}</b>
                          </div>
                        <div v-if="value2.input == '0' && value2.type == 'currency'">
                          <h6>{{ value2["label"] }}</h6>
                          <b>{{ this.currency(value2["input"]) }}</b>
                        </div>
                      </template>
                    </div>
                  </div>
                  <template v-for="(value2, ind2) in value.fieldGroups" :key="ind2">
                    <fieldset v-if="!value2.visibility?true:checkVisibility(value2.visibility)">
                      <!-- <legend>{{ convertSentenceCase(value2["name"]) }}</legend> -->
                      <legend>{{ convertSentenceCase(value2["label"]) }}</legend>
                      <div class="pro-details">
                        <template class="" v-for="(value3, ind3) in value2.fields" :key="ind3">
                          <div class="mb-4" v-if="value3.type == 'static'">
                            <div>
                              <h6>{{ value3["label"] }}</h6>
                            </div>
                          </div>
                          <div class="mb-4" v-if="value3.input != ''">
                            <div v-if="value3.type == 'single-select'">
                              <h6>{{ value3["label"] }}</h6>
                              <b>{{ getSelectValue(value3.value, value3["input"]) }}</b>
                            </div>
                            <div v-else-if="value3.type == 'currency'">
                              <h6>{{ value3["label"] }}</h6>
                              <b>{{ this.currency(value3["input"]) }}</b>
                            </div>
                            <div v-else-if="value3.type == 'multi-select'">
                              <h6>{{ value3["label"] }}</h6>
                              <!-- <b>{{ getMultiSelectValue(value3.value, value3["input"]) }}</b> -->
                              <b>{{ value3["input"].toString() }}</b>
                            </div>
                            <div v-else-if="value3.type == 'boolean'">
                              <h6>{{ value3["label"] }}</h6>
                              <b>{{ (value3["input"] == '1')?'Yes':'No' }}</b>
                            </div>
                            <div v-else-if="value3.type == 'date' && value3.input">
                              <h6>{{ value3["label"] }}</h6>
                              <b>{{ dateconvert(value3["input"]) }}</b>
                            </div>
                            <div v-else-if="value3.type == 'file'">
                              <h6>{{ value3["label"] }}</h6>
                              <button type="button" class="btn btn-primary eyeicon-btn" data-toggle="modal" data-target="#imageModal" v-if="formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].input" @click="previewFiles(formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].input)">
                                <img src="~@/assets/images/eye.png" alt="" />
                              </button>
                            </div>
                            <div v-else-if="value3.type == 'checkbox'">
                              <h6>
                                <img v-if="value3.input" src="~@/assets/images/check-mark.svg" class="check-markimg" alt=""/> 
                                {{ value3["label"] }}
                              </h6>
                              <!-- <b>{{ value3["input"] }}</b> -->
                            </div>
                            <div v-else>
                              <h6>{{ value3["label"] }}</h6>
                              <b>{{ value3["input"] }}</b>
                            </div>
                          </div>
                          <div v-if="value3.input == '0' && value3.type == 'number'"> <!-- to handle case where input is zero and type number -->
                            <h6>{{ value3["label"] }}</h6>
                            <b>{{ value3["input"] }}</b>
                          </div>
                          <div v-if="value3.input == '0' && value3.type == 'currency'">
                            <h6>{{ value3["label"] }}</h6>
                            <b>{{ this.currency(value3["input"]) }}</b>
                          </div>
                        </template>
                      </div>

                      
                      <!-- 333333 -->
                      <template v-for="(value3, ind3) in value.fieldGroups[ind2].fieldGroups" :key="ind3">
                        <fieldset v-if="!value3.visibility?true:checkVisibility(value3.visibility)">
                          <legend>{{ convertSentenceCase(value3["label"]) }}</legend>
                          <div class="pro-details">
                            <template class="" v-for="(value4, ind4) in value3.fields" :key="ind4">
                              <div class="mb-4" v-if="value4.type == 'static'">
                                <div>
                                  <h6>{{ value4["label"] }}</h6>
                                </div>
                              </div>
                              <div class="mb-4" v-if="value4.input != ''">
                                <div v-if="value4.type == 'single-select'">
                                  <h6>{{ value4["label"] }}</h6>
                                  <b>{{ getSelectValue(value4.value, value4["input"]) }}</b>
                                </div>
                                <div v-else-if="value4.type == 'currency'">
                                  <h6>{{ value4["label"] }}</h6>
                                  <b>{{ this.currency(value4["input"]) }}</b>
                                </div>
                                <div v-else-if="value4.type == 'multi-select'">
                                  <h6>{{ value4["label"] }}</h6>
                                  <!-- <b>{{ getMultiSelectValue(value4.value, value4["input"]) }}</b> -->
                                  <b>{{ value4["input"].toString() }}</b>
                                </div>
                                <div v-else-if="value4.type == 'boolean'">
                                  <h6>{{ value4["label"] }}</h6>
                                  <b>{{ (value4["input"] == '1')?'Yes':'No' }}</b>
                                </div>
                                <div v-else-if="value4.type == 'date' && value4.input">
                                  <h6>{{ value4["label"] }}</h6>
                                  <b>{{ dateconvert(value4["input"]) }}</b>
                                </div>
                                <div v-else-if="value4.type == 'file'">
                                  <h6>{{ value4["label"] }}</h6>
                                  <button type="button" class="btn btn-primary eyeicon-btn" data-toggle="modal" data-target="#imageModal" v-if="formInfo.fieldGroups[ind].fieldGroups[ind2].fieldGroups[ind3].fields[ind4].input" @click="previewFiles(formInfo.fieldGroups[ind].fieldGroups[ind2].fieldGroups[ind3].fields[ind4].input)">
                                    <img src="~@/assets/images/eye.png" alt="" />
                                  </button>
                                </div>
                                <div v-else-if="value4.type == 'checkbox'">
                                  <h6>
                                    <img v-if="value4.input" src="~@/assets/images/check-mark.svg" class="check-markimg" alt=""/> 
                                    {{ value4["label"] }}
                                  </h6>
                                  <!-- <b>{{ value4["input"] }}</b> -->
                                </div>
                                <div v-else>
                                  <h6>{{ value4["label"] }}</h6>
                                  <b>{{ value4["input"] }}</b>
                                </div>
                              </div>
                              <div v-if="value4.input == '0' && value4.type == 'number'"> <!-- to handle case where input is zero and type number -->
                                <h6>{{ value4["label"] }}</h6>
                                <b>{{ value4["input"] }}</b>
                              </div>
                              <div v-if="value4.input == '0' && value4.type == 'currency'">
                                <h6>{{ value4["label"] }}</h6>
                                <b>{{ this.currency(value4["input"]) }}</b>
                              </div>
                            </template>
                          </div>
                        </fieldset>
                      </template>
                      <!-- 333333 -->



                    </fieldset>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="otp-section" >
          <div class="text-center" v-if="!sendOtp">
            <button class="next-btn mb-4" @click="getOTP()">Get OTP</button>
          </div>
          <div class="form-control1" v-if="sendOtp">
            <input type="text" v-model="inputedOtp">
            <p class="error-text" v-if="error.inputedOtp">
              {{ error.inputedOtp }}
            </p>
          </div>
          <div v-if="sendOtp" class="d-flex justify-content-between align-items-center">
            <button class="next-btn mb-4" @click="resendOTP()">Resend OTP</button> &nbsp;&nbsp;
            <button class="next-btn mb-4" @click="submitOTP()">Submit OTP</button>
          </div>
          <div>
            <span class="text-success" v-if="otpSuccess">OTP Verified Successfully.</span>
            <span class="text-danger" v-if="otpFailed">Invalid OTP Entered. Please Provide Valid OTP.</span>
          </div>
        </div> -->
        <!-- <div  class="button wrap d-flex justify-content-center">
          <button type="submit" class="next-btn mb-4" @click="buyPolicy()">
            Proceed <i><img src="~@/assets/images/next-arrow.png" /></i>
          </button>
        </div> -->
        <div class="error-msg" :innerHTML="errorMsgs">
          <!-- {{ errorMsgs }} -->
        </div>
        
        
        <!-- <div class="button wrap d-flex justify-content-center">
          <button type="submit" class="next-btn mb-4" @click="buyPolicy()">
            Proceed <i><img src="~@/assets/images/next-arrow.png" /></i>
          </button>
        </div> -->

        <div class="button wrap d-flex justify-content-center">
        <div class="footer-total-premium d-flex justify-content-between align-items-center position-absolute">
           <div class="total-premium">
            <b>Total Premium: </b>
               &#8377; <strong>
                 
            {{this.currency(this.plan)}}
            </strong>/
            {{
              getPayMode(this.mode)
            }}  
             <span class="feature-name">
              <img src="~@/assets/images/info-button.png" alt=""/>
              <div class="tooltip-sec">
                 <table>
                  <tr>
                    <td>  
                      Premium:
                    </td>
                    <td>₹ 
                      {{
                      this.currency(this.premiumValue)
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">  
                      Taxes:
                    </td>
                    <td>₹  
                      {{this.currency(this.taxValue)}}
                    </td>
                  </tr>
                </table>
              </div>
            </span> 
            <span class="premium-tax">Premium (Including Taxes)</span>
          </div> 
          <div v-if="this.b2c">
                <button type="submit" class="next-btn mt-0 d-flex justify-content-center align-items-center" id="accept" data-toggle="modal" data-target="#otpModal" v-on:click="getOTP()">
                  Proceed <i><img src="~@/assets/images/next-arrow.png" /></i>
              </button>
            </div>
           <button v-else type="submit" class="next-btn mt-0 d-flex justify-content-center align-items-center" @click="buyPolicy()">
            Proceed <i><img src="~@/assets/images/next-arrow.png" /></i>
          </button>
        </div>
        </div>

        

      </div>
      <div class="button wrap d-flex justify-content-center">
    <div class="d-lg-none footer-total-premium d-flex justify-content-between align-items-center position-absolute">
      <div class="total-premium">
        <b>Total Premium: </b>
        &#8377; <strong>{{
            this.currency(this.plan)
          }}</strong>/{{
            getPayMode(this.mode)
          }}
          <span class="feature-name">
              <img src="~@/assets/images/info-button.png" alt=""/>
              <div class="tooltip-sec">
                <table>
                  <tr>
                    <td>  
                      Premium:
                    </td>
                    <td>₹ 
                      {{
                      this.currency(this.premiumValue)
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">  
                      Taxes:
                    </td>
                    <td>₹  
                      {{this.currency(this.taxValue)}}
                    </td>
                  </tr>
                </table>
              </div>
            </span>
            <span class="premium-tax">Premium (Including Taxes)</span>


      </div>
      
        
        <div v-if="this.b2c">
                <button type="submit" class="next-btn mt-0 d-flex justify-content-center align-items-center" id="accept" data-toggle="modal" data-target="#otpModal" v-on:click="getOTP()">
                  Proceed <i><img src="~@/assets/images/next-arrow.png" /></i>
              </button>
            </div>
         <button v-else type="submit" class="next-btn mt-0 d-flex justify-content-center align-items-center" @click="buyPolicy()">
            Proceed <i><img src="~@/assets/images/next-arrow.png" /></i>
          </button> 
          
            <!-- <div class="bttn-grp d-flex">
              <button type="submit" class="next-btn mb-4" id="accept" data-toggle="modal" data-target="#otpModal" v-on:click="getOTP()">
                Accept 
            </button>&nbsp;&nbsp;
            <button type="submit" class="next-btn mb-4" data-toggle="modal" data-target="#exampleModal">
                Reject 
            </button>
                        
            </div> -->
                 
      
    </div>
      </div>
    </div>
      <hr>
    <complianceText/>
  </div>
  


 <!--OTP Modal-->
   <div class="modal fade" id="otpModal" tabindex="-1" role="dialog" aria-labelledby="otpModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="loading_block_outer" v-if="loadingOTP">
              <Loading3 :msg='loadingMessage'/>
              <div class="snippet" data-title=".dot-spin">
                <div class="stage">
                  <div class="dot-spin"></div>
                </div>
              </div>
            </div>
            <div class="modal-header">
                <h5 class="modal-title">Validate OTP</h5>
                <button type="button" class="close" @click="otpModalReset()" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <input class="form-control" id="exampleFormControlTextarea2" placeholder="Please enter your 6 digit OTP" v-model="otp" :maxlength=6 @keypress="isNumber($event)"/>
                <span v-if="otpSent != null" style="font-size:13px"> {{ otpSent }} </span>
                <label class = "p-2" v-if="otpMessage != 'No'" for="exampleFormControlTextarea2"> {{ otpValid }} </label>
                <a v-if="!disabledResend">
                    <button class="cursor-pointer p-2" @click="getOTP()" style="color:red; border:none; background-color:white; float: right;">Resend</button>
                </a>
                <a v-else>
                    <span v-if="otpSent != null" class="p-2" style="text-align: center; font-weight: bold; color: #0a3f88; float: right;"> {{timerMinutes}}:{{timerSeconds}}</span>
                </a>
            </div>
            <div class="modal-footer justify-content-center">
                <button type="button" class="next-btn mb-4" data-dismiss="modal" @click="otpModalReset()" style="width:100px" >Close</button>
                <button type="button" class="next-btn mb-4" @click="validateOTP()" style="width:100px" >Submit</button>
            </div>
            </div>
        </div>
        </div>
        <!--OTP Modal end-->
  <!-- Modal -->
    <div class="modal fade" id="imageModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Selected File</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                  <div v-if="!fileToDisplay" class="error_msg text-center doc_upload_block2">No Document Selected</div>
                  <div v-else>
                    <img v-if="typeToDisplay == 'image'" class="image1" :src="fileToDisplay">
                    <iframe v-else width="100%" height="400px" :src="fileToDisplay"></iframe>
                  </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    <!-- ./End Modal -->

</div>
</template>


<script>
import moment from 'moment';
import $ from "jquery";
import complianceText from "@/components/footer/complianceText.vue";
import {getPolicyCover, getConpareData, getInsuranceImages, getDistributer} from '@/mixins/common'
import Loading2 from "@/components/loading2.vue";
import Loading3 from "@/components/loading3.vue";
export default {
  name: 'proposaldetailscheck',
  mixins:[getPolicyCover, getConpareData, getInsuranceImages, getDistributer],
  components: {
    Loading2,
    Loading3,
   complianceText,

  }, 
  mounted() {
    // Getting selected plan
    
    let sPlan= JSON.parse(sessionStorage.getItem("selectedPlanForCheckout"));
    this.selectedPlan=sPlan
    // this.includeAddOnsPremium();
    // this.includeRidersPremium();


    this.plan=this.selectedPlan.totalPremiumDetails[0].totalPremiumWithTax;
     this.mode=this.selectedPlan.individualDetails[0].premiumDetails[0].mode;
     this.premiumValue=this.selectedPlan.totalPremiumDetails[0].totalPremiumValue;
     this.taxValue=this.selectedPlan.totalPremiumDetails[0].totalTax;
    console.log('selected plan: ', this.selectedPlan,this.plan);
    this.selectedPlan = JSON.parse(sessionStorage.getItem("selectedPlanForCheckout"));
    this.selectedPlanData = JSON.parse(sessionStorage.getItem("selected_plan_data"));
    console.log('selected plan: ', this.selectedPlan);
    console.log('selected plan Data : ', this.selectedPlanData);
    // Getting previously filled data
   
    this.formInfo = JSON.parse(sessionStorage.getItem("filledData"));
    
    if(!this.formInfo) {
      // Redirecction Logic
      
    } else {
      //console.log(this.formInfo)
      console.log('this.form info iii: ', this.formInfo);
     
      //let containerObject = new JSONObject(this.formInfo);
      //has method
      if (this.formInfo.fieldGroups.otpDetails) {
        //get Value of video
        console.log('otp present');
      } else {
        console.log('otp not present');
      }
    }
    if (sessionStorage.getItem("sp_details_data")) {
      this.spDetails = JSON.parse(sessionStorage.getItem("sp_details_data"));
    }

    if(sessionStorage.getItem('proposal_ui_id')) {
      this.proposalUiId = JSON.parse(sessionStorage.getItem('proposal_ui_id'));
    }

    if(sessionStorage.getItem('uiQuoteId')) {
      this.uiQuoteId = JSON.parse(sessionStorage.getItem('uiQuoteId'));
      console.log('this.uiQuoteId: ', this.uiQuoteId);
    }

    // Setting Distributer ID
    this.distributorID = getDistributer()["distributerID"];
    this.distributorName = getDistributer()["distributorName"];
    this.distributorLogo = getDistributer()["distributorLogo"];
    this.contactEmail = getDistributer()["contactEmail"];
    this.infoEmail = getDistributer()["infoEmail"];
    this.contactNumber = getDistributer()["contactNumber"];
    console.log('this.distributorName: ', this.distributorName);

    let thanksURL= process.env.VUE_APP_URL_RMD
      this.thankYouUrl=thanksURL+"/v1/lifeterm/paymentStatus?proposal_ui_id=" + this.proposalUiId + "&transaction_id=" + this.$route.query.transactionId;
    
     if(this.formInfo["manufacturerId"]=="HDFC")
      {
       this.applicationNo=this.selectedPlanData["trackInfo"]["varFields"][0]["fieldValue"]
       let qData = {
           "proposal_manu_id": this.applicationNo,
           "proposal_ui_id": this.proposalUiId,
          };
       this.saveApplicationNumber(qData);
      }
      //hdfc app number display
      if(this.selectedPlanData["insuranceAndProducts"]["insuranceCompanyCode"] == "HDFC")
       {
       this.applicationNo=this.selectedPlanData["trackInfo"]["varFields"][0]["fieldValue"]
       console.log("applicationzy" ,this.applicationNo);
       
       
       
       }
       if(sessionStorage.getItem("rm_data"))
       {
        let rmData=JSON.parse(sessionStorage.getItem("rm_data"))
        if(rmData["RoleId"]=="1")
        this.b2c=true;
        else
        this.b2c=false
       }
    

  },
  unmounted(){
    clearInterval(this.timer)
  },
  data() {
    return {
      otpTransactionId:"",
      policyNo:"",
      proposalJson:{},
      selectedPlan: "",
      plan:"",
      mode:"",
      premiumValue:"",
      taxValue:"",
      applicationNo:"",
      selectedPlanData:"",
      formInfo: "",
      spDetails: "",
      loading: false,
      apiError: false,
      spDetailsError: false,
      thankYouUrl: "",
      proposalUiId: "",
      uiQuoteId: "",
      distributorID: "",
      distributorName:"",
      distributorLogo:"",
      sendOtp: false,
      resendOtp: false,
      submitOtp: false,
      loadingOTP:false,
      loadingMessage:"",
      otpSent:"",
      otpExpiryTime:"",
       timerMinutes: "",
      timerSeconds: "",
      disabledResend:true,
      inputedOtp: "",
      otpSuccess: false,
      otpFailed: false,
      randOtp: "",
      error: [],
      errorMsgs: "",
      typeToDisplay: "",
      fileToDisplay: "",
      b2c:false,
    } 
  },
  methods: {
    // includeAddOnsPremium() {
    //   let addOnsAmt = 0;
    //   let addOnsAmtTotal = 0;
    //   let addOnsTax = 0;
    //   this.selectedPlan.productDetails.AddOnSection.addOnSelected.forEach(
    //     (element, index) => {
    //       if( element.premiumIncludedFlag == 'No') {
    //         addOnsAmt += element.premiumDetails[0].premiumValue;
    //         addOnsTax += element.premiumDetails[0].tax[0].taxValue;
    //         addOnsAmtTotal += element.premiumDetails[0].totalPremiumValue;
    //       }
    //     }
    //   );
    //   this.selectedPlan.individualDetails[0].premiumDetails[0].premiumValue += addOnsAmt;
    //   this.selectedPlan.individualDetails[0].premiumDetails[0].tax[0].taxValue += addOnsTax;
    //   this.selectedPlan.individualDetails[0].premiumDetails[0].totalPremiumValue += addOnsAmtTotal;
    // },
    // includeRidersPremium() {
    //   let riderAmt = 0;
    //   let riderAmtTotal = 0;
    //   let riderTax = 0;
    //   this.selectedPlan.individualDetails[0].rider.forEach(
    //     (element, index) => {
    //       riderAmt += element.premiumDetails[0].riderPremiumValue;
    //       riderTax += element.premiumDetails[0].tax[0].taxValue;
    //       riderAmtTotal += element.premiumDetails[0].totalRiderPremiumValue;
    //     }
    //   );
    //   this.selectedPlan.individualDetails[0].premiumDetails[0].premiumValue += riderAmt;
    //   this.selectedPlan.individualDetails[0].premiumDetails[0].tax[0].taxValue += riderTax;
    //   this.selectedPlan.individualDetails[0].premiumDetails[0].totalPremiumValue += riderAmtTotal;
    // },
     isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    saveApplicationNumber(qData){
        // let qData = {
        //    "proposal_manu_id": this.applicationNo,
        //    "proposal_ui_id": this.proposalUiId,
        //    "proposal_policy_no": this.policyNo
        //   };
        var headers = {
          "quoteId":this.uiQuoteId,
          Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
          "Content-Type": "application/json",
          };
        
        this.backendApi("saveApplicationNumber", qData, headers)
          .then((response) => {
            if(response == 'error') {
              self.apiError = true;
            
            }
           
             else if(response) {
              console.log('proposal status updated: ', response.data);
              console.log('proposal status updated saveappnumapi: ', response.data);

              
            }
          })
          .catch((error) => {
            console.log('error msg block email', error);
            
          });
    },
    convertSentenceCase(value) {
      const result = value.replace(/([A-Z])/g, " $1");
      const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      return finalResult;
    },
     currency(amt) {
      console.log(amt);
      return new Intl.NumberFormat('en-IN').format(amt)
    },
     getPayMode(val) {
      if (val == "M") {
        return "Month";
      } else if (val == "Q") {
        return "Quarter";
      } else if (val == "HY") {
        return "Half Year";
      } else if (val == "Y") {
        return "Year";
      }
    },
    getShow(tab) {
      if(tab == 'proposerDetails') {
        return "show";
      } else {
        return '';
      }
    },
    getInsurerImgs(value) {
      let img = getInsuranceImages();
      return require("../../assets/images/" + img[value]);
    },
    sendMailToCustomer()
    {
      console.log(new URL(location).origin+"/#/proposaldetailscheckdiy");
      let rmData = JSON.parse(sessionStorage.getItem("rm_data"));
      console.log(rmData);
      var resumeLink= new URL(location).origin+"/#/proposaldetailscheckdiy?proposalId="+this.proposalUiId+'&transactionId='+rmData['transaction_id'];
      console.log("printResumeLink",resumeLink);
      var emailBody = `<!DOCTYPE html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:th="http://www.thymeleaf.org">
   <head>
      <meta charset="ISO-8859-1" />
      <title>Insert title here</title>
   </head>
   <body>
      <table width="700"border="0"cellspacing="0" cellpadding="0" align="center"  bgcolor="#EEF3FB">
         <tr>
            <td
               style="  font-family: Public Sans; font-weight: 600; font-size: 18px;  color: #1f1f1f; padding: 20px 0px; text-align: center;" >
               <center><img style="width:50%"src="` + this.distributorLogo + `" ></center>
            </td>
         </tr>
         <tr>
            <td>
               <table width="700" border="0" cellspacing="0" cellpadding="0"  align="center"   bgcolor="#fff" style="border-radius: 5px; background-color: #fff;
                  margin-left: 30px; margin-right: 30px;" >
                  <tr>
                     <td width="50"></td>
                     <td
                        style="
                        font-family: Public Sans; font-weight: 600; font-size: 18px;color: #1f1f1f;padding: 20px 0px 10px 0px;" >
                        Dear ${this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['firstName']['input']} ${this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['lastName']['input']},
                     </td>
                     <td width="50"></td>
                  </tr>
                  <tr>
                     <td width="20"></td>
                     <td
                        style="font-family: Public Sans;font-size: 18px;color: #1f1f1f;padding: 20px 0px;line-height: 28px;">
                        Thank you for choosing ${this.selectedPlan["insuranceAndProducts"]["productName"]}.<br>
                         Your application is ready!<br>
						             Please review your application. If you are satisfied with the details, kindly approve the form with OTP verification and proceed for payment.
                        <center>
                           <br/>
                           <a style=" background-color: #E2046E;
                              text-decoration-line: none;
                              padding: 10px;
                              border-radius: 4px;
                              border-color: transparent;
                              color: white;
                              font-weight: bold;" href=${resumeLink}> Review and Pay </a>
                        </center>
                     </td>
                     <td width="50"></td>
                  </tr>
                  <tr>
                     <td width="50"></td>
                     <td style=" border-bottom: 1px solid #f0f0f0; font-size: 14px; color: #1f1f1f; padding: 10px 0px 0px 0px; " ></td>
                     <td width="50"></td>
                  </tr>

                  <tr>
                     <td width="50"></td>
                     <td
                        style="
                        font-family: Public Sans; font-size: 14px;  color: #1f1f1f; padding: 20px 0px; line-height: 22px;" >
                        For any assistance, please feel free to contact us at 1800-267-2670 or email us at ${this.infoEmail}
                     </td>
                     <td width="50"></td>
                  </tr>
				  <tr>
                     <td width="50"></td>
                     <td
                        style="font-family: Public Sans;font-size: 18px;color: #1f1f1f;padding: 20px 0px;line-height: 28px;" >
                        Regards,<br>
                        ${this.distributorName}
                     </td>
                     <td width="50"></td>
                  </tr>
				  <tr>
                     <td width="50"></td>
                     <td
                        style="font-family: Public Sans; font-size: 10px;  color: #1f1f1f; padding: 20px 0px; line-height: 22px;" >
                        *Insurance is the subject matter of solicitation. CIN No.: U67200MH2002PTC137896 | IRDA License No.: 118 |Validity: 24.02.2021 - 23.02.2024| Category: Composite Brokers.                     </td>
                     <td width="50"></td>
                  </tr>
               </table>
            </td>
         </tr>
         <tr>
            <td
               style="  font-family: Public Sans; font-size: 12px;  text-align: center; opacity: 0.5; color: #1f1f1f;  padding: 10px; " >
               <center><b style="color: black;"> Powered by </b><strong style="color: blue;font-size: 12px;">9Platforms Technology Pvt Ltd.</strong></center>
            </td>
         </tr>
      </table>
   </body>
</html>`;


        // let qData = {
        //   "send_from":"lifepolicy@pincinsure.com",
        //   "subject":"Your application for "+this.selectedPlan["insuranceAndProducts"]["productName"],
        //   "text": emailBody,
        //   "send_to":[this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['email']['input']]
        // // this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['email']['input']
        // };
        let qData = {
                      data:{
                        "distributor": {
                            "distributorID": this.distributorID,
                            "agentID": "",
                            "salesChannel": "",
                            "channelType": rmData.RoleId !=="1" ? "B2B" : "B2C",
                            "varFields": [{
                                          "fieldName":"",
                                          "fieldValue":""
                                        }]
                        },
                        "eventName": "reviewAndPay",
                        "data": [
                            {
                                "resumeLink": resumeLink,
                                "mobileNumber": this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['mobileNumber']['input'],
                                "channel": "sms"
                            },
                            {
                                "resumeLink": resumeLink,
                                "fullName": this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['firstName']['input'] + " " + this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['lastName']['input'],
                                "productName": this.selectedPlan["insuranceAndProducts"]["productName"],
                                "emailId": [this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['email']['input']],
                                "channel": "email"
                            }
                        ]
                    }
                  }
        var headers = {
          "quoteId":this.uiQuoteId,
          Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
          "Content-Type": "application/json",
        };
        this.backendApi("sendEmailandSMS", qData, headers)
          .then((response) => {
            console.log('sms and email: ', response);
            this.loading = false;
            if(response == 'error') {
              self.apiError = true;
            // let msg=['We are facing some issue while sending an e-mail']

            // this.$router.push({ path: '/networkalert', query: {backUrl:'proposal-details-check', msg: msg }});
              // alert("Error occurred while sending Mail. Please try again!");
            }
            else if(response.status.error=="error")
            {
            let msg=['We are facing some issue while sending an e-mail']

            this.$router.push({ path: '/networkalert', query: {backUrl:'proposal-details-check', msg: msg }});
            }
             else if(response && response.data){
              
              this.$router.push('/thankyoumail');
              //window.location.href = new URL(location).origin
              
            }
          })
          .catch((error) => {
            console.log('error msg block email', error);
          let msg=['We are facing some issue from backend while sending an e-mail']
            this.$router.push({ path: '/networkalert', query: {backUrl:'proposal-details-check', msg: msg }});
          });
    },
    saveProposalStatus(status){
      let propoalData = {
        "proposal_ui_id": this.proposalUiId.toString(),
        "journey_status": status
      };

      var headers = {
        "quoteId":this.uiQuoteId,
        Authorization: "Basic " + window.btoa(process.env.VUE_APP_DATA_PER_USERNPASS),
        "Content-Type": "application/json",
      };
      
      //console.log('qData: ', qData);
      this.backendApi("saveProposal", propoalData, headers)
        .then((response) => {

        })
        .catch((error) => {
          console.log('error saving proposal status', error);
        });
    },
    buyPolicy() {

      this.convertProposalJson();
      //let diy_flag= process.env.VUE_DIY_JOURNEY
      let diy_flag = 0;
      // if(diy_flag == undefined)
      //   diy_flag =1;
      if(this.formInfo['manufacturerId']=="TATA"||this.formInfo['manufacturerId']=="ICICI"||this.formInfo['manufacturerId']=="ABSLIFEI" )
      {
        this.saveProposalStatus("Proposal Review");
        this.loading = true;
        diy_flag =1;
      }
      if(diy_flag == 1)
      {
        if(this.formInfo['manufacturerId']=="ICICI")
        {
          this.validateProposal();
        }
        else
        {
          this.updateProposalStatus(11);
          sessionStorage.setItem("proposalStatusValue", JSON.stringify("Awaiting Client Approval"));
          this.sendMailToCustomer();
        }        
      }
      else
      {
      
        // if(this.spDetails.source) {

          var self = this;
          this.errorMsgs = "";
          this.selectedPlan = JSON.parse(sessionStorage.getItem("selectedPlanForCheckout"));
          //let productApiUrl = this.selectedPlan.insuranceAndProducts.insuranceCompanyCode + '/' + this.selectedPlan.insuranceAndProducts.productCode;

          //specific to ipru
          // this.formInfo.fieldGroups.other.fields.partnerThankYouUrl['input'] = this.thankYouUrl;
          // this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.annualIncome.input = parseInt(this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.annualIncome.input);
          // this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.annualIncome.input = parseInt(this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.annualIncome.input);

        let reqData = {
          "data": {
            "quoteResponse": { "data": { "quote": [this.selectedPlan], "request": JSON.parse(sessionStorage.getItem('quote_request')) } },
            "data": this.proposalJson.data
          },
          "proposalUiId":this.proposalUiId
        };

          // let headers = {
          //   Authorization: "Basic " + window.btoa(process.env.VUE_APP_PMSNPASS),
          //   "Content-Type": "application/json",
          // };
          let headers ={"quoteId":this.uiQuoteId,};
          
          this.loading = true;
          this.backendApi("proposalVersionApi", reqData, headers)
            .then((response) => {
              self.loading = false;
              self.apiError = false;
              if(response.data.errors){
                if(response.data.errors.length > 0)
                {
                  //Update proposal status to "Proposal Error"
                  this.updateProposalStatus(12);
                  //this.saveProposalResponse(reqData,response.data);
                  //self.apiError = true;
                  
                  let ck = 1;
                  response.data.errors.forEach(function(element) {
                    self.errorMsgs += ck +'. '+ element.errorMessage +'<br/>';
                    ck++;
                  });
                }
                else
                {
                  if(response['status'] == 'error') {
                    //Update proposal status to "Proposal Error"
                    this.updateProposalStatus(12);
                    //this.saveProposalResponse(reqData,response.data);
                    //self.apiError = true;
                    
                    let ck = 1;
                    response.data.errors.forEach(function(element) {
                      self.errorMsgs += ck +'. '+ element.errorMessage +'<br/>';
                      ck++;

                    });

                  } else if(response && response.data) {
                    //Update proposal status to "Proposal Submitted"
                    this.updateProposalStatus(9);
                    //this.saveProposalResponse(reqData,response.data);
                    console.log('Submit proposal response: ', response.data)
                    self.loading = false;
                    //this.response = response.data;

                    let reqData2 = [this.selectedPlan.insuranceAndProducts.productCode, this.selectedPlan.insuranceAndProducts.insuranceCompanyCode, response.data.reqId];
                    this.reqTimeOut();
                    this.getProposalPoll(reqData2);
                    
                  }
                }
              }
              else
              {
                if(response['status'] == 'error') {
                  //Update proposal status to "Proposal Error"
                  this.updateProposalStatus(12);
                  //this.saveProposalResponse(reqData,response.data);
                  //self.apiError = true;
                  console.log('inside error: ', response.data);
                  let ck = 1;
                  response.data.errors.forEach(function(element) {
                    self.errorMsgs += ck +'. '+ element.errorMessage +'<br/>';
                    ck++;
                  });

                } else if(response && response.data) {
                  //Update proposal status to "Proposal Submitted"
                  this.updateProposalStatus(9);
                  //this.saveProposalResponse(reqData,response.data);
                  console.log('Submit proposal response: ', response.data)
                  self.loading = false;
                  //this.response = response.data;

                  let reqData2 = [this.selectedPlan.insuranceAndProducts.productCode, this.selectedPlan.insuranceAndProducts.insuranceCompanyCode, response.data.reqId];
                  this.reqTimeOut();
                  this.getProposalPoll(reqData2);
                  
                }
              }


              // console.log('Sumbit proposal success', response);
              // self.loading = false;
              // self.apiError = false;
              // if(response['status'] == 'error') {
              //   //self.apiError = true;
              //   console.log('inside error: ', response.data);
              //   let ck = 1;
              //   response.data.errors.forEach(function(element) {
              //     self.errorMsgs += ck +'. '+ element.errorMessage +'<br/>';
              //     ck++;
              //   });

              // } else if(response && response.data) {
              //   console.log('Submit proposal response: ', response.data)
              //   self.loading = false;
              //   //this.response = response.data;

              //   let reqData2 = [this.selectedPlan.insuranceAndProducts.productCode, this.selectedPlan.insuranceAndProducts.insuranceCompanyCode, response.data.reqId];
              //   this.reqTimeOut();
              //   this.getProposalPoll(reqData2);
                
              // }
            })
            .catch((error) => {
              console.log('error msg block submit proposal', error);
              self.loading = false;

            });

        // } else {
        //   this.$router.push({ path: '/networkalert', query: { status: 'Error',backUrl:"proposal-details-check", msg: 'SP Details not available' }});

          // alert('SP Details not available');
        // }
      }
    },
    proposalAppStatus() {
      let rmData=JSON.parse(sessionStorage.getItem("rm_data"));
      let qData = {
        "data":{
          "distributor": {
                            "distributorID": this.distributorID,
                            "agentID": "",
                            "salesChannel": "",
                            "channelType": rmData.RoleId !=="1" ? "B2B" : "B2C",
                            "varFields": [{
                                            "fieldName":"",
                                            "fieldValue":""
                                          }]
                        },
          "insuranceCompanyCode": this.formInfo['manufacturerId'],
          "applicationNo": this.applicationNo
        }
      };
      var headers = {
        "quoteId": this.uiQuoteId,
        Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
        "Content-Type": "application/json",
      };
      this.loading = true;
      setTimeout( () => {
        this.backendApi("proposalAppStatus", qData, headers)
        .then((response) => {
          if (response == 'error') {
            self.apiError = true;
          }
          else if (response && response.data) {
            try{
              let status = response.data['data']['manufacturer'][0]['product'][0]['applicationStatus']['manufacturerAppStatus']
              if(status!=="Scrutiny" && status!=="Requirements Awaited"){
                this.proposalAppStatus();
              } else {
                this.updateProposalStatus(9);
                // this.thankYouUrl = new URL(location).origin+"/#/thankyoupage?appnum="+ this.applicationNo+"&mob="+this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.mobileNumber.input+"&dob="+this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.dateOfBirth.input+"&email="+this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.email.input+"&mobcd=91";

                this.thankYouUrl = new URL(location).origin+"/#/thankyoupage";
                window.location.href = this.thankYouUrl;
                // window.history.replaceState("", "", this.thankYouUrl);
                // window.location.reload();
              }
            } catch(e) {
              this.proposalAppStatus();
            }
          }
        })
        .catch((error) => {
          console.log('error msg block email', error);
        });
      },10000);
      
    },
    updateProposalStatus(statusid)
    {
        let qData = {
          "proposal_ui_id":this.proposalUiId,
          "status_id": statusid
          };
        var headers = {
          "quoteId":this.uiQuoteId,
          Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
          "Content-Type": "application/json",
          };
        
        this.backendApi("ProposalStatus", qData, headers)
          .then((response) => {
            if(response == 'error') {
              self.apiError = true;
            
            }
           
             else if(response && response.data) {
              console.log('proposal status updated: ', response.data);
              
            }
          })
          .catch((error) => {
            console.log('error msg block email', error);
            
          });
    },
    getProposalPoll(reqData) {
      let self = this;
      console.log('get proposal called: ', reqData);
      // let headers = {
      //   Authorization: "Basic " + window.btoa(process.env.VUE_APP_PMSNPASS),
      //   "Content-Type": "application/json",
      // };
      let headers = {"quoteId":this.uiQuoteId,};
      this.loading = true;

      setTimeout(() => {
        console.log("set time out called")
          this.backendApi("proposalPollApi", reqData, headers, "get")
          .then((response) => {
            console.log('Get proposal poll success');
            //self.loading = false;
            self.apiError = false;
            if(response.errors)
            {
              if(response.errors.length > 0){
                //Update proposal status to "Proposal Error"
                this.updateProposalStatus(12);
                clearTimeout(this.timer);
                self.apiError = true;
                self.loading = false;
                let ck = 1;
                response.errors.forEach(function(element) {
                  self.errorMsgs += ck +'. '+ element.errorMessage +'<br/>';
                  ck++;
                });
              }
            }
            if(response.error)
            {
              if(response.error.length > 0){
                //Update proposal status to "Proposal Error"
                this.updateProposalStatus(12);
                clearTimeout(this.timer);
                self.apiError = true;
                self.loading = false;
                let ck = 1;
                response.error.forEach(function(element) {
                  self.errorMsgs += ck +'. '+ element.message +'<br/>';
                  ck++;
                  
                });
              }
            }
            if(response.data.errors)
            {
              if(response.data.errors.length > 0){
                //Update proposal status to "Proposal Error"
                this.updateProposalStatus(12);
                clearTimeout(this.timer);
                self.apiError = true;
                self.loading = false;
                let ck = 1;
                response.data.errors.forEach(function(element) {
                  self.errorMsgs += ck +'. '+ element.errorMessage +'<br/>';
                  ck++;
                });
                return;
              }
            }
            if(response.data.error)
            {
              if(response.data.error.length > 0){
                //Update proposal status to "Proposal Error"
                this.updateProposalStatus(12);
                clearTimeout(this.timer);
                self.apiError = true;
                self.loading = false;
                let ck = 1;
                response.data.error.forEach(function(element) {
                  self.errorMsgs += ck +'. '+ element.message +'<br/>';
                  ck++;
                });
                return;
              }              
            }
            if(response == 'error') {
              //Update proposal status to "Proposal Error"
              this.updateProposalStatus(12);
              self.apiError = true;
              self.loading = false;
            } else if(response && response.data){
              

              if(response.data?.data?.message) {
                
                //console.log('nside internal message');
                if(response.data.data.message == 'Please wait we are still processing the request') {
                  self.getProposalPoll(reqData);
                }

                if(response.data.data.message == 'No Response from manufacturer') {
                  clearTimeout(this.timer);
                  self.loading = false;
                  let m="No response from manufacturer"
                  let msg=[]
                  msg.push(m)
                  
                  let errorMsg="No response from manufacturer"
                  this.$router.push({ path: '/networkalert', query: {backUrl:"proposal-details-check",msgError:errorMsg, reqId:response.data.reqId, msg: msg }});
                }
              } else if(response.data?.status) {

                if(this.formInfo['manufacturerId']!="HDFC")
                {
                  try{
                    this.applicationNo=response.data.ManufacturerTracking.applicationNumber
                    this.policyNo=response.data.ManufacturerTracking.policyNumber
                  }catch(err){
                    console.log('error in assigning appno/polno: ', err);
                  }
                  sessionStorage.setItem("policyNo",this.policyNo)
                  sessionStorage.setItem("applicationNumber",this.applicationNo)

                  let qData = {
                    "proposal_manu_id": this.applicationNo,
                    "proposal_ui_id": this.proposalUiId,
                    "proposal_policy_no": this.policyNo
                  };
                  this.saveApplicationNumber(qData);
                }
                

                if(response.data.status.message == 'Success' || response.data.status.code == 'E00') {
                  clearTimeout(this.timer);
                  self.loading = false;
                  
                  let productCode_DIY;
                  if(sessionStorage.getItem('filledData')) {
                    productCode_DIY = JSON.parse(sessionStorage.getItem("filledData"));
                  }
                  
                  if(productCode_DIY["manufacturerId"]=="HDFC")
                  {
                    this.thankYouUrl = new URL(location).origin+"/#/thankyoupage";
                    window.location.href = this.thankYouUrl;
                  }
                  
                } else {
                  
                  self.loading = false;
                  this.lastresponse = JSON.stringify(response.data.status);
                  clearTimeout(this.timer);
                  let m="Received Error response from manufacturer: "+this.lastresponse
                  let msg=[]
                  msg.push(m)
                  // alert("Received Error response from manufacturer: "+this.lastresponse)
                  //Updating proposal status tp 'Proposal Error'
                  this.updateProposalStatus(12);
                  //this.sendMailToCustomerError();
                  // window.location.href = new URL(location).origin + "/#/proposaldetailscheckdiy?proposalId="+this.$route.query.proposalId+"&transactionId="+this.$route.query.transactionId;
                  //self.getProposalPoll(reqData);
                  let errorMsg="We are facing some issue while fetching poll Api"
                  this.$router.push({ path: '/networkalert', query: {backUrl:"proposal-details-check",msgError:errorMsg, reqId:response.data.reqId, msg: msg }});
                }
              }   
            }
          })
          .catch((error) => {
            self.loading = false;
            console.log('error msg block get proposal poll', error);

          });
      }, 10000);
    },
    checkVisibility(value) {
      //return eval(value);
      if(value) {
        let obj = this.getFinalObject(value);
        try {
          return eval(obj);
        }
        catch(err) {
          console.log('error exp: ', value);
        }
      } else {
        return true;
      }
    },
    getFinalObject(tempResponse) {
      //console.log('tempResponse: ', tempResponse)
      var tempString = JSON.stringify(tempResponse);
      var updatedString = tempString.replace(/'input' in formInfo\[/g, "formInfo[");
      var updatedString2 = updatedString.replace(/formInfo\[/g, "this.formInfo[");
      //var updatedString3 = updatedString2.replace(/this.this./g, "this.");
      //console.log('final String: ', updatedString3);
      return JSON.parse(updatedString2); 
    },
    editDetails(tabIndex) {
      if(confirm("Do you really want to edit these details?")){
        localStorage.setItem("filledDataTab", JSON.stringify(tabIndex));
        sessionStorage.setItem("filledDataTab", JSON.stringify(tabIndex));
        this.$router.push('/proposal');
      }
    },
    getSelectValue(array, val) {
      if(array) {
        let itemIndex = array.findIndex(item => item.Value == val);
        if(array[itemIndex]) {
         return array[itemIndex]['Text'];
        }
      } else {
        return '';
      }
    },
    getMultiSelectValue(array, array2) {
      // console.log('getMultiSelectValue called: ', array, array2);
      if(array2) {
        let tmpVal = [];
        for(var i = 0; i < array2.length; i++) {
          var x = this.getSelectValue(array, array2[i]);
          if(x) {
            tmpVal.push(x);
          }
        }
        return tmpVal;
      }
    },
    dateconvert(value){
      const formattedDate = moment(value).format('DD/MM/YYYY')
     // console.log(formattedDate)
      return formattedDate;
    },

    getOTP() {
      this.saveProposalStatus("Proposal Review");
      console.log('get otp called');
      let rmData=JSON.parse(sessionStorage.getItem("rm_data"));
      this.otpSent = null;
         this.otpValid = null;
         this.loadingOTP = true;
         this.loadingMessage = "Sending OTP";
        this.disabledResend = true;

      let otpReq = {
        "data":{
        "additionalSetup":{		
          "userCountry":"",	
          "userRegion":"",
          "userLanguage":"",
          "userIP":"",
          "userAgent":"",
          "userGeoLocation":{	
            "latitude":"",
            "longitude":""
          }
        },
        "distributor":{
          "distributorID": this.distributorID,
          "agentID":"",
          "salesChannel":"Others",
          "channelType": rmData.RoleId !=="1" ? "B2B" : "B2C",
          "varFields":[{
            "fieldName":"",
            "fieldValue":""
          }]
        },
        "insuranceCompanyCode": this.selectedPlan.insuranceAndProducts.insuranceCompanyCode,	
        "applicationNo":"",
        "policyNo":"",
        "quoteId": this.uiQuoteId.toString(),
        "UITrackingRefNo": this.proposalUiId,
        "OTPDetails":{	
          "mobileNumber": this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.mobileNumber.input,	
          "email":""
        },
        "varFields":[{		
          "fieldName":"",
          "fieldValue":""
        }]
      }};

      console.log('otpReq: ', otpReq);

      // this.loading = true;
      let headers = {"quoteId":this.uiQuoteId,};
      this.backendApi("sendOtpApi", otpReq, headers)
        .then((response) => {
          // this.loading = false;
          this.loadingOTP = false;
          this.otpTransactionId = response.data.data.OTPTransactionId;
          this.otpExpiryTime = response.data.data.ExpiryTime;
          this.otpSent = "OTP has been sent successfully";
          this.resendOTP();
          console.log('get otp response', response);
         
        })
        .catch((error) => {
          console.log('error msg block get otp on proposal confirmation', error);
          self.loading = false;
        });

      /* let smsUserName = process.env.VUE_APP_SMS_USERNAME;
      let smsPassword = process.env.VUE_APP_SMS_PASSWORD;
      let smsFrom = process.env.VUE_APP_SMS_FROM;
      let smsUdh = process.env.VUE_APP_SMS_UDH;
      this.randOtp = Math.floor(100000 + Math.random() * 900000);
      let mobileNumber = this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.mobileNumber.input;
      let msgText = 'Hi ' + this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.firstName.input +', \n Your life term OTP is: ' + this.randOtp + '';
      let headers = {};
      let reqData = [
        smsUserName,
        smsPassword,
        mobileNumber,
        smsFrom,
        smsUdh,
        msgText
      ];

      console.log('Mobile: ', mobileNumber, 'Msg: ', msgText);
       this.backendApi("sendSMS", reqData, headers, "get")
          .then((response) => {
            console.log('Send SMS success: ', response);
          })
          .catch((error) => {
          self.loading = false;
          console.log('error msg block send sms', error);
        });
      this.sendOtp = true; */
    }, 
       resendOTP() {
    var exp = Date.parse(this.otpExpiryTime);
    console.log("expiryTime = ",this.otpExpiryTime);
    let self = this;
      const t = setInterval( function(){
        var timeleft = ((exp - new Date().getTime()));
        self.timerMinutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
        self.timerSeconds = Math.floor((timeleft % (1000 * 60)) / 1000);
        self.timerMinutes = self.timerMinutes < 10 ? "0"+self.timerMinutes : self.timerMinutes;
        self.timerSeconds = self.timerSeconds < 10 ? "0"+self.timerSeconds : self.timerSeconds;
        // console.log("minutes = ",self.timerMinutes);
        // console.log("seconds = ",self.timerSeconds);
        if (timeleft <= 0 || self.otpSent == null){
            clearInterval(t);
            self.disabledResend = false;
            self.timerMinutes = "";
            self.timerSeconds = "";
        }
    },1000);
      this.resendOtp = true;
    },
    
    otpModalReset(){
        this.otpSent= null;
        this.otpValid= null;
        this.otp = "";
        this.otpMessage = null;
        this.disabledResend = true;
        this.timerMinutes = "";
        this.timerSeconds = "";
    },
     validateOTP(){
      if(!this.otp){
        this.otpSent = "Please enter OTP";
        this.otpValid = null;
      }
    else{
        console.log('validate otp called');
        let rmData=JSON.parse(sessionStorage.getItem("rm_data"))
         let reqBody = {"data":{"distributor": {
                                "distributorID": this.distributorID,
                                "agentID": "",
                                "salesChannel": "",
                                "channelType": rmData.RoleId !=="1" ? "B2B" : "B2C",
                                "varFields": [{
                                                "fieldName":"",
                                                "fieldValue":""
                                              }]
                            },
                            "insuranceCompanyCode": this.selectedPlan.insuranceAndProducts.insuranceCompanyCode,
                            "UITrackingRefNo": this.proposalUiId,
                            "OTPDetails":{
                                "OTP": this.otp,
                                "OTPTransactionId":this.otpTransactionId
                            }
        }};
        // console.log('validate otpReq: ',reqBody);
        // let headers = {Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
        // "Content-Type": "application/json",
        // };
        this.loadingOTP = true;
        this.loadingMessage = "Validating your OTP";
        let headers= {"quoteId":this.uiQuoteId,};
        this.backendApi("valOtpApi", reqBody, headers)
        .then((response) => {
          this.loadingOTP = false;
          if(this.formInfo.manufacturerId == "TATA"){
            this.loadingMessage = "Redirecting to Payment Gateway";
          } else{
            this.loadingMessage = "Application submission is in progress";
          }
          this.otpMessage = response.data.data.OTPAccepted;
          // console.log("validated otp response = ", response);
          if(this.otpMessage == "Yes"){
            
            $("#otpModal").modal("hide");
            // this.loading = true;
            this.otpModalReset();
            let productCode_DIY;
              this.buyPolicy();
            // let hdfc_payment_url="https://onlineinsuranceuat.hdfclife.com/thirdparty?appnum="+ this.applicationNo+"&mob="+this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.mobileNumber.input+"&dob="+this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.dateOfBirth.input+"&email="+this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.email.input+"&mobcd=91"
            // window.location.href=hdfc_payment_url
            // console.log("hdfc_payment_url",hdfc_payment_url)
            
          }
          else{
            // console.log('validate otp response', response.data.data.OTPAccepted);
            this.otpMessage = null;
            this.otpValid = !this.otp ? "Please enter OTP" :response.data.data.reason;
            this.otp = "";
            this.otpSent = null;
          }
        })
        .catch((error) => {
          console.log('error msg block get otp on proposal confirmation', error);
          self.loading = false;
        });
    }
    },
    submitOTP() {
      console.log('submit otp called');

      let submitOtpReq = {
        "distributor":{
          "distributorID": this.distributorID,
          "agentID":"",
          "salesChannel":"",	
          "varFields":[{
            "fieldName":"",
            "fieldValue":""
          }]
        },
        "insuranceCompanyCode": this.selectedPlan.insuranceAndProducts.insuranceCompanyCode,	
        "applicationNo":"",
        "policyNo":"",
        "quoteId": this.uiQuoteId,	
        "OTPDetails":{
          "OTP":"",	
          "OTPTransactionId":""
        },
        "varFields":[{
          "fieldName":"",
          "fieldValue":""
        }]
      };

      console.log('submitOtpReq: ', submitOtpReq);
      this.loading = true;
      let headers = {};
      this.backendApi("submitOtp", submitOtpReq, headers)
        .then((response) => {
          this.loading = false;
          console.log('submit otp response', response);
        })
        .catch((error) => {
          console.log('error msg block get otp on proposal confirmation', error);
          self.loading = false;
        });

      /* this.error = []; 
      if(this.inputedOtp.length > 0) {
        this.submitOtp = true;
        this.otpSuccess = true;
        console.log('inputed OTP: ', this.inputedOtp);
      } else {
        this.error["inputedOtp"] = "Please Enter OTP";
      } */
    },
    reqTimeOut() {
      console.log('request timeout called');
      this.timer = setTimeout(() => {
        this.$router.push({ path: '/alert', query: { status: 'Error',backUrl:"proposal-details-check", msg: "No response received, Please try again" }});

        // alert('No response received, Please try again.');
        //this.$router.go();
      }, 180000);
    },
    previewFiles(imgData) {
      console.log('previewFiles called: ', imgData)
      const type = imgData.split(';')[0].split('/')[0].split(':')[1];
      console.log('imgData type:', type);
      this.typeToDisplay = type;
      this.fileToDisplay = imgData;
    },

    convertProposalJson()
    {
      console.log("response data after last tab",this.formInfo)
       this.proposalJson["data"]={}
      this.proposalJson["data"]=JSON.parse(JSON.stringify(this.formInfo))
      console.log("pro json",this.proposalJson)
      // for(let key in this.responseData)
      // {
      //   if(key==sectionIndex)
      //   {
          // this.proposalJson["data"]["fieldGroups"][sectionIndex]=JSON.parse(JSON.stringify(this.responseData[key]))
          for(let grandParentKey in this.proposalJson["data"])
          {
            // if(grandParentKey!="fieldGroups")
            // {
            //   delete this.proposalJson["data"][grandParentKey]
            // }
            if(grandParentKey=="fieldGroups"){
            //level 1
            for(let parentKey in this.proposalJson["data"][grandParentKey])
            {

            
          for(let checkfg in this.proposalJson["data"][grandParentKey][parentKey] )
          {
             if(checkfg!="fieldGroups" && checkfg!="fields")
             {
              
              console.log("section",checkfg)
              delete this.proposalJson["data"][grandParentKey][parentKey][checkfg]
             }
             else if(checkfg=="fieldGroups")
             {
              //level 2
                for(let childKey in this.proposalJson["data"][grandParentKey][parentKey][checkfg])
                {


                   for(let checkChildfg in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey])
                   {
                    if(checkChildfg!="fields" && checkChildfg!="fieldGroups")
                    {
                      //  console.log("checkChildfg",checkChildfg)
                      delete this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg]
                    }
                    else if(checkChildfg=="fields")   
                    {
                        for(let childKeyfieldsChild in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg])
                        {
                            for(let childKeyfieldsChildKey in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][childKeyfieldsChild])
                            {
                                 if(childKeyfieldsChildKey!="input")
                                 {
                                  delete this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][childKeyfieldsChild][childKeyfieldsChildKey]
                                 }
                            }

                        }
                    }
                    else if(checkChildfg=="fieldGroups")
                    {
                      //level 3
                        for(let fg_fg_fg_key in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg])
                        {
                              for(let fg_fg_fg_key_f in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key])
                              {
                                if(fg_fg_fg_key_f!="fields" && fg_fg_fg_key_f!="fieldGroups")
                                {
                                  delete this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key][fg_fg_fg_key_f]
                                }
                                else if(fg_fg_fg_key_f=="fields")
                                {
                                  for(let fg_fg_fg_key_f_key in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key][fg_fg_fg_key_f])
                                  {
                                      for(let fg_fg_fg_key_f_key_input in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key][fg_fg_fg_key_f][fg_fg_fg_key_f_key])
                                      {
                                        if(fg_fg_fg_key_f_key_input!="input")
                                        {
                                          delete this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key][fg_fg_fg_key_f][fg_fg_fg_key_f_key][fg_fg_fg_key_f_key_input]

                                        }
                                      }
                                  }
                                }
                                else if(fg_fg_fg_key_f=="fieldGroups")
                                  {
                                    //level 4
                                      for(let fg_fg_fg_key_f_key in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key][fg_fg_fg_key_f])
                                      {
                                            for(let fg_fg_fg_key_f_key_f in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key][fg_fg_fg_key_f][fg_fg_fg_key_f_key])
                                            {
                                              if(fg_fg_fg_key_f_key_f!="fields" && fg_fg_fg_key_f_key_f!="fieldGroups")
                                              {
                                                delete this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key][fg_fg_fg_key_f][fg_fg_fg_key_f_key][fg_fg_fg_key_f_key_f]
                                              }
                                              else if(fg_fg_fg_key_f_key_f=="fields")
                                              {
                                                for(let fg_fg_fg_key_f_key_f_key in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key][fg_fg_fg_key_f][fg_fg_fg_key_f_key][fg_fg_fg_key_f_key_f])
                                                {
                                                    for(let fg_fg_fg_key_f_key_f_key_input in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key][fg_fg_fg_key_f][fg_fg_fg_key_f_key][fg_fg_fg_key_f_key_f][fg_fg_fg_key_f_key_f_key])
                                                    {
                                                      if(fg_fg_fg_key_f_key_f_key_input!="input")
                                                      {
                                                        delete this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key][fg_fg_fg_key_f][fg_fg_fg_key_f_key][fg_fg_fg_key_f_key_f][fg_fg_fg_key_f_key_f_key][fg_fg_fg_key_f_key_f_key_input]

                                                      }
                                                    }
                                                }
                                              }
                                            }
                                      }
                                  }
                              }
                        }
                    }
                   }

                    
                }
             }
             else if(checkfg=="fields")
             {
              for(let childofFields in this.proposalJson["data"][grandParentKey][parentKey][checkfg])
              {
                   for(let childOfFieldsOfChildKey in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childofFields])
                   {
                      if(childOfFieldsOfChildKey!="input")
                      {
                        delete this.proposalJson["data"][grandParentKey][parentKey][checkfg][childofFields][childOfFieldsOfChildKey]
                      }
                   }
              }
             }
          }
            }
            }
          }
          
          console.log("after filter proposal json",this.proposalJson)
      //   }
      // } 
    },
    validateProposal()
    {
      var self = this;
      self.errorMsgs = "";
      this.selectedPlan = JSON.parse(sessionStorage.getItem("selectedPlanForCheckout"));
      // this.convertProposalJson()
      let reqData = {
        "data":
        {
          "quoteResponse": { "data": { "quote": [ this.selectedPlan ],"request":JSON.parse(sessionStorage.getItem('quote_request'))}},
          "data": this.proposalJson["data"]
        }
      };

      let headers = {"quoteId":this.uiQuoteId,};      
      this.loading = true;
      this.backendApi("validateProposal", reqData, headers)
        .then((response) => {
             
          //this.loading = false;
          self.apiError = false;
          if(response.data.errors){
            if(response.data.errors.length > 0)
            {
              this.loading = false;
              self.apiError = true;
              let ck = 1;
              
              

              response.data.errors.forEach(function(element) {
                console.log("errorMsg",response)
                self.errorMsgs += ck +'. '+ element.errorMessage +'<br/>';
                ck++;
              });
            }
            else
            {
              if(response && response.data) {
                // Updating status to Awaiting Client Approval
                this.updateProposalStatus(11);
                sessionStorage.setItem("proposalStatusValue", JSON.stringify("Awaiting Client Approval"));
                this.sendMailToCustomer();
              }
            }
          }
          
        })
        .catch((error) => {
          console.log('error msg block submit proposal', error);
          self.loading = false;
          let msg=['We are facing some issue from backend while validating proposal data']
            this.$router.push({ path: '/networkalert', query: {backUrl:'proposal-details-check', msg: msg }});

        });
    }

  },
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/components/checkout.scss';
@import '~@/assets/scss/components/proposal-page.scss';

</style>